<template>
  <dashboard-layout>
    <div class="w-10/12 mx-auto">
      <div class="p-4 flex justify-between">
        <h1 class="text-3xl">
          Novo Usuário
        </h1>
        <div class="p-4 flex">
          <a href="javascript:history.go(-1)">
            Voltar para a lista de usuários</a
          >
        </div>
      </div>
      <form-wizard
        @on-complete="onComplete"
        :start-index="0"
        color="#69CA5E"
        title=""
        subtitle=""
        finish-button-text="Finalizar"
      >
        <tab-content title="Acesso" icon="ti-check">
          <div
            class="w-12/12 border border-green-600  rounded-lg py-3 px-5 mx-auto mt-5"
          >
            <div class="flex w-full mb-5">
              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="name">Usuário</label>
                <input
                  v-model="access.name"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="name"
                  type="text"
                  placeholder="Usuário"
                />
              </div>

              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="email-user">E-mail</label>
                <input
                  v-model="access.email"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="email-user"
                  type="text"
                  placeholder="E-mail"
                />
              </div>
            </div>
            <div class="flex w-full mb-5">
              <div class="flex w-4/12 flex-col mr-5">
                <label class="mb-2" for="password">Senha</label>
                <input
                  v-model="access.password"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="password"
                  type="password"
                  placeholder="Senha"
                />
              </div>

              <div class="flex w-4/12 flex-col mr-5">
                <label class="mb-2" for="confirm_password"
                  >Confirmar Senha</label
                >
                <input
                  v-model="access.confirm_password"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="confirm_password"
                  type="password"
                  placeholder="Confirmar Senha"
                />
              </div>

              <div class="flex w-4/12 flex-col mr-5">
                <label class="mb-2" for="user-role">Perfil de Usuário</label>
                <vue-select
                  id="user-role"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  placeholder="Selecione uma opção"
                  :options="access.user_roles"
                />
              </div>
            </div>
            <div class="flex w-full mb-5">
              <div class="flex w-4/12 flex-col mr-5">
                <label class="mb-2" for="grant-access-at"
                  >Início do Acesso</label
                >
                <input
                  v-model="access.grant_access_at"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grant-access-at"
                  type="text"
                  placeholder="Início do Acesso"
                />
              </div>

              <div class="flex w-4/12 flex-col mr-5">
                <label class="mb-2" for="revoke-access-at">Fim do Acesso</label>
                <input
                  v-model="access.revoke_access_at"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="revoke-access-at"
                  type="text"
                  placeholder="Fim do Acesso"
                />
              </div>

              <div class="flex w-4/12 flex-col mr-5">
                <label class="mb-2" for="status-user">Status</label>
                <vue-select
                  id="status-user"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  placeholder="Selecione uma opção"
                  :options="access.status"
                />
              </div>
            </div>
          </div>
        </tab-content>
      </form-wizard>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '@/layouts/DashboardLayout';
import store from '@/store';
import axios from 'axios';

export default {
  components: {
    DashboardLayout
  },
  data() {
    return {
      access: {
        name: '',
        email: '',
        password: '',
        confirm_password: '',
        user_type: '',
        user_roles: [],
        grant_access_at: '',
        revoke_access_at: '',
        status: [
          {
            label: 'Ativo',
            value: 1
          },
          {
            label: 'Inativo',
            value: 0
          }
        ]
      }
    };
  },
  methods: {
    onComplete() {
      axios
        .post(
          store.state.baseUrl + '/api/usuarios',
          {
            name: this.access.name,
            email: this.access.email,
            password: this.access.password
          },
          {
            headers: store.state.header
          }
        )
        .then(response => {
          this.users = response.data.data;
        });
    },
    async fetchUserRoles() {
      await axios
        .get(store.state.baseUrl + '/api/papeis', {
          headers: store.state.header
        })
        .then(data => {
          this.access.user_roles = data.data;
        });
    }
  },
  created() {
    this.fetchUserRoles();
  }
};
</script>
